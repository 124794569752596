import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Tooltip, Button } from '@dv01-inc/waterfall-ui';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Background</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Common Usage at dv01</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Keyboard Interaction</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Positioning</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Variations</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Design Notes</AnchorLink>
    </AnchorLinks>
    <h2>{`Background`}</h2>
    <p>{`A tooltip is a popup that displays information related to an element when the element receives keyboard focus or the mouse hovers over it. It typically appears after a small delay and disappears when Escape is pressed or on mouse out.`}</p>
    <p>{`Tooltips do not receive focus. A hover that contains focusable elements can be made using a non-modal dialog.`}</p>
    <h2>{`Common Usage at dv01`}</h2>
    <p>{`Icons should always be implemented with their corresponding tooltip. This can include the name of the icon and/or a short phrase explaining what the icon does.`}</p>
    <h2>{`Keyboard Interaction`}</h2>
    <p>{`Escape: Dismisses the Tooltip.`}</p>
    <p>{`Note`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Focus stays on the triggering element while the tooltip is displayed.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If the tooltip is invoked when the trigger element receives focus, then it is dismissed when it no longer has focus (onBlur). If the tooltip is invoked with mouseIn, then it is dismissed with on mouseOut.`}</p>
      </li>
    </ul>
    <h2>{`Positioning`}</h2>
    <p>{`Default:`}</p>
    <ul>
      <li parentName="ul">{`centered above the element`}</li>
      <li parentName="ul">{`with the tip of the triangle touching the element`}</li>
    </ul>
    <p>{`Other options:`}</p>
    <ul>
      <li parentName="ul">{`Centered below the element`}</li>
      <li parentName="ul">{`To the right of the element`}</li>
      <li parentName="ul">{`To the left of the element`}</li>
    </ul>
    <h2>{`Variations`}</h2>
    <Row mdxType="Row">
  <Column colMd={3} colLg={3} className="pt-10" mdxType="Column">
    <Tooltip content="Tooltip text" mdxType="Tooltip">
      <Button label="button" mdxType="Button" />
    </Tooltip>
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "path=src/button src=https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx",
            "path": "src/button",
            "src": "https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx"
          }}>{`<Tooltip content="Tooltip text">
  <Button label="button">
</Tooltip>
`}</code></pre>
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      